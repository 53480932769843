<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    <div style="background-color: #f8f9f9" class="container-fluid">
      <!-- start page title -->
      <div class="row">
        <div class="col-12">
          <div
            class="
              page-title-box
              d-sm-flex
              align-items-center
              justify-content-center
            "
          >
            <h1 style="margin-top: 50px" class="mb-lg-8">CONTACT US</h1>
          </div>
        </div>
      </div>
      <!-- end page title -->

      <div class="row">
        <div class="col-5">
          <div class="card">
            <div class="card-body">
              <h4>
                <i
                  class="ri-arrow-right-fill"
                  style="color: #000"
                  data-uw-styling-context="true"
                ></i
                ><span
                  class="important"
                  style="color: #000 !important; margin-left: 30px"
                  data-uw-styling-context="true"
                >
                  SINERJINAT</span
                >
              </h4>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <h4>
                <i
                  class="fas fa-map-marker-alt"
                  style="color: #000"
                  data-uw-styling-context="true"
                ></i
                ><span
                  class="important"
                  style="color: #000 !important; margin-left: 30px"
                  data-uw-styling-context="true"
                >
                  Route du Bac Rades 2040</span
                >
              </h4>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <h4>
                <i
                  class="fas fa-phone"
                  style="color: #000"
                  data-uw-styling-context="true"
                ></i
                ><span
                  class="important"
                  style="color: #000 !important; margin-left: 30px"
                  data-uw-styling-context="true"
                >
                  +216 71 448 271</span
                >
              </h4>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <h4>
                <i
                  class="fas fa-fax"
                  style="color: #000"
                  data-uw-styling-context="true"
                ></i
                ><span
                  class="important"
                  style="color: #000 !important; margin-left: 30px"
                  data-uw-styling-context="true"
                >
                  +216 71 448 271</span
                >
              </h4>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <h4>
                <i
                  class="ri-mail-fill"
                  style="color: #000"
                  data-uw-styling-context="true"
                ></i
                ><span
                  class="important"
                  style="color: #000 !important; margin-left: 30px"
                  data-uw-styling-context="true"
                >
                  sinerjinat.info@sinerji-grup.com</span
                >
              </h4>
            </div>
          </div>
        </div>
        <!-- end col -->
        <div class="col-7">
          <div class="card">
            <div class="card-body">
              <form ref="form" @submit.prevent="sendEmail">
                <div class="mb-3">
                  <h4>Name</h4>
                  <input
                    v-model="user_name"
                    name="user_name"
                    type="text"
                    class="form-control"
                    required=""
                    placeholder=""
                  />
                </div>

                <div class="mb-3">
                  <h4>E-Mail</h4>
                  <div>
                    <input
                      v-model="user_email"
                      name="user_email"
                      type="email"
                      class="form-control"
                      required=""
                      parsley-type="email"
                      placeholder=""
                    />
                  </div>
                </div>
                <div class="mb-3">
                  <h4>Phone</h4>
                  <div>
                    <input
                    v-model="contact_number"
                      name="contact_number"
                      type="text"
                      class="form-control"
                      required=""
                      placeholder=""
                    />
                  </div>
                </div>

                <div class="mb-3">
                  <h4>Message</h4>
                  <div>
                    <textarea
                    v-model="message"
                      name="message"
                      required=""
                      class="form-control"
                      rows="5"
                    ></textarea>
                  </div>
                </div>
                <div class="mb-0">
                  <div>
                    <button
                      type="submit"
                      class="btn btn-primary waves-effect waves-light me-1"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../components/Header.vue";

import Footer from "../components/Footer.vue";

import emailjs from "@emailjs/browser";
/**
 * Dashboard component
 */
export default {
  data() {
    return {
      user_name: "",
      user_email: "",
      contact_number:'',
      message:''
    };
  },
  components: {
    Header,

    Footer,
  },
  methods: {
    sendEmail() {
      emailjs
        .sendForm(
          "service_8xsmgtg",
          "template_cn1i08b",
          this.$refs.form,
          "HIq0tHC8Vh08t9nrt"
        )
        .then(
          (result) => {
            this.user_name = "";
            this.user_email = "";
            this.contact_number = "";
            this.message = "";
            console.log("SUCCESS!", result.text);
          },
          (error) => {
            console.log("FAILED...", error.text);
          }
        );
    },
  },
};
</script>